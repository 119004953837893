.theme-lite-bg {
    background: #dbeafe;
}
.header-bg {
    background: #e5e7eb;
}
.svg-btn-red {
    fill: #ff2424;
}
.navbtn {
    height: 65px;
    font-size: 19px;
    justify-content: center;
    width: 100%;
    padding: 17px 7px 9px;
    font-weight: 700;
    color: #707072;
    background: #d2d1d5;
    box-shadow: 2px 3px 5px #bdbfc4;
    border-radius: 5px;
    box-shadow: 2px 3px 5px #bdbfc4, -5px -5px 10px #ffffff;
  }
  .navbtn:hover {
    box-shadow: inset 5px 5px 10px #000000;
    background: #6b7280;
    color: #ffffff;
    transition: 0.2s ease-in;
  }
  .navactive {
    /* box-shadow: inset 5px 5px 10px #7c2400;
    background: #ff4d00; */
    box-shadow: inset 5px 5px 10px #000000;
    background: #6b7280;
    color: #ffffff;
    transition: 0.2s ease-in;
  }
  .navactive .navicon {
    fill: #ffffff !important;
  }
  .navbtn .navicon {
    fill: #2f2f30;
    height: 30px;
  }
  .navbtn:hover .navicon {
    fill: #ffffff !important;
  }

  .bgPrime2 {
    background: #b9cfe4
   }

   .functionbox .fill-hover {
    transition: .4s;  
  }
  .functionbox:hover .fill-hover {
    fill: #e8f2ff;
    transition: .4s;
  }
  .btn_active{
  background: #F0F0F0;
  box-shadow: inset -4px -4px 4px rgba(255, 255, 255, 0.6), inset 4px 4px 4px #BFC1C5;
}
.btn_inactive{
  background: #E3E3E3;
box-shadow: -4px -4px 4px rgba(255, 255, 255, 0.6), 4px 4px 4px #BFC1C5;
}
.btn_inactive:hover{
  background: #F0F0F0;
  box-shadow: inset -4px -4px 4px rgba(255, 255, 255, 0.6), inset 4px 4px 4px #BFC1C5;
}
.dysing-input-green {
  box-shadow: -1px -1px 5px #fff, 2px 2px 5px #6e6e6e80 inset;
  background-color: #68F988;
}
.diceembossed-btn {
  background: #b9cfe4;
  border-radius: 7px;
  box-shadow: -4px -4px 4px #cae2f8, 4px 4px 10px #00000040;
  color: #2f3034;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  transition: .5s;
}
.dysing-bg {
  background-color: #C5D9EC;
}
.diceembossed-btn:hover {
  background: #a6c4e0;
}
.dysing-motormodel {
  background: linear-gradient(104deg, #F2F7FC -2.48%, #DEE6EE 103.65%);
  border: 1px solid rgba(118, 117, 122, 0.5);
  box-shadow: 3px 3px 15px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.dysing-motorbtn{
  background: #E1E7ED;
  box-shadow: 3px 2px 4px rgba(0, 0, 0, 0.25), -4px -4px 10px #FFFFFF;
  border-radius: 5px;
}
.dysing-input {
  background-color: #9cc8e0;
}
.dysing-input, .dysing-input-yellow {
  box-shadow: -1px -1px 5px #fff, inset 2px 2px 5px #6e6e6e80;
}

.secondary_tabs .tab_button-radio input[type='radio']:checked + label {
    background-color: theme('colors.tabActive');
    color: #000;
    opacity: 1;
    border: unset;
  }
  
  .secondary_tabs .tab_button-radio label {
    background-color: theme('colors.tabDefault');
    border: unset;
    color: #000;
    margin-right: 2px;
    font-weight: normal;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }