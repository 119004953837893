.efembtn .small-btn,
.small-btn {
  padding: 15px 10px;
  font-size: 15px;
  line-height: 1.1;
}

.efembtn button.active {
  background-color: #fbbf24;
}

.btn-inactive {
  pointer-events: none;
}

.efemactive {
  background: #93ffa8 !important;
  box-shadow: none !important;
}

.disabled-gray {
  background: #e5e7eb !important;
  box-shadow: none !important;
}

.efembtn button {
  background: theme('colors.btnSecondary');
  padding: 15px;
  color: black;
  font-size: 17px;
  border-radius: 10px;
  box-shadow: 2px 3px 6px #00000066;
  font-weight: 500;
  transition: 0.5s;
}
.efembtn button:hover {
  background: #cee4ff;
  box-shadow: none;
}
.efemactive {
  background: #93ffa8 !important;
  box-shadow: none !important;
}
.secondary-frame {
  height: calc(100vh - 285px);
  overflow-y: auto;
  overflow-x: hidden;
}
.secondary_tabs {
  padding: 10px 0 0 10px;
  display: flex;
  gap: 0;
  /* background: #cccccc; */
  overflow: hidden;
}
