@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Your custom CSS goes here */
:root {
  --primary: 191, 95%, 68%;
  --primary-foreground:#fff;
  --secondary: 200, 100%, 90%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Button shadow style in e95 rightintrface */
.custom-shadow {
  box-shadow: 1px 1px 3px 0 rgb(138, 141, 146), -2px -2px 3px 0 rgb(255, 255, 255);
}

.custom-shadow-hover:hover {
  box-shadow: inset -2px -2px 5px 0 rgb(255, 255, 255), inset 1px 1px 3px 0 rgb(138, 141, 146);
}
.bg-customGray {
  background: #E3EDF6;
}
.btnBg {
  background: #E1E7ED;
}

/* button ripple effect start */
.ctmBtn span {
  display: flex;
  position: relative;
  z-index: 1;
}
.ctmBtn span::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  background: rgba(255, 255, 255, 0.7);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transform: scale(0);
}
button.ctmBtn:focus:not(:active) span::after {
  animation: circleGrow 0.3s linear;
}
@keyframes circleGrow {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}
/* button ripple effect end */
