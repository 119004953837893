---
.main-content-section {
  padding-top: 40px;
}
.editrecipebox {
  position: relative;
  top: unset;
  background: white;
  width: 440px;
  margin: 0px auto;
  border-radius: 10px;
  box-shadow: 0px 1px 10px #00000054;
  overflow: hidden;
}

.editrecipebox .popclose {
  background: #ff3131;
  width: 24px;
  height: 24px;
  color: white;
  border-radius: 20px;
  padding: 4px;
  right: 9px;
  top: 13px;
  font-size: 9px !important;
  position: absolute;
}
.editrecipebox .popTitle {
  font-size: 22px;
  padding: 9px 0px 9px 15px;
  background: #393939;
  color: white;
}
.editrecipebox .popcontent {
  flex-grow: 1;
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
  max-width: 96%;
  margin: 10px auto;
}
.fileOptions button {
  font-size: 15px;
  height: 40px;
  margin: 5px 4px;
  border-radius: 7px;
  padding: 3px 11px;
  background: white;
  color: black;
  transition: 0.5s;
}
.fileOptions button:hover {
  background: #ffc107;
}
.editrecipebox input {
  display: block;
  width: 100%;
  padding: 0.5rem 1.1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#newWorkflow {
  float: left;
  color: #ffffff !important;
  background-color: #fa5114 !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-radius: 0.5rem;
}
button#newWorkflow {
  float: right;
  height: 39px;
  margin-top: 7px;
  margin: 15px -8px 7px 0;
}
.editoptions button {
  background: #ffffff;
  color: black;
  border: 1px solid #989898;
  padding: 0px 10px;
  height: 40px;
  transition: 0.5s;
  margin: 10px 0px;
  border-radius: 7px;
  box-shadow: 4px 4px 4px 0px #00000063;
}

.editoptions button:hover {
  background: #4c4c4c;
  color: white;
  border: 1px solid #080808;
}

.innerHeader {
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  background: #575757;
  color: white;
}
.colmax-100 th {
  max-width: 100px;
}

/* table.table-bordered{
  border:1px solid rgb(65, 65, 65);
}*/
.border-0 {
  border: none;
}
table.table-bordered > thead > tr > th {
  border: 1px solid rgb(65, 65, 65);
  min-width: 100px;
}
table.table-bordered > tbody > tr > td {
  border: 1px solid rgb(65, 65, 65);
  min-width: 100px;
}
.tablescroll {
  overflow: scroll;
  flex-grow: 1;
  height: 550px;
}
.table tbody:before {
  height: 0px;
}
.table > :not(:first-child) {
  border-top: 0px solid #ffffff00;
}
.nowrap {
  white-space: nowrap;
}

.editrecipebox .popcontent-no-border {
  flex-grow: 1;
  border: none;
  padding: 5px;
  max-width: 96%;
  margin: 10px auto;
}
.choose_template_or_recipe_modal_wrapper .popcontent .buttons-holder {
  display: flex;
  align-items: center;
  margin: 15px 0;
  max-width: 70%;
  gap: 20px;
}

.list_item {
  padding: 0px 5px;
  font-weight: bold;
  color: black;
  background-color: white;
  cursor: pointer !important;
}
.list_item:hover {
  color: white;
  background-color: rgb(51, 119, 236);
}
.e87_tabs {
  padding: 10px 0 0 10px;
  display: flex;
  gap: 0;
  background: #cccccc;
  overflow: hidden;
}

.recipe_landing {
  height: calc(100vh - 111px);
}

.recipe_landing .tab_content {
  padding: 10px 0px 15px 0px;
}

.recipe_landing .recipe_item {
  border: 1px solid rgb(65, 65, 65);
  padding: 5px 5px 5px 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.recipe_landing .recipe_item:hover {
  color: white;
  background-color: rgb(51, 119, 236);
}

.recipe_landing .template_item {
  border: 1px solid rgb(65, 65, 65);
  padding: 5px 5px 5px 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.recipe_landing .template_item:hover {
  color: white;
  background-color: rgb(51, 119, 236);
}

/* E87 styles */
button.e-87 {
  background: theme('colors.btnSecondary');
  padding: 5px 10px;
  color: black;
  font-size: 17px;
  border-radius: 10px;
  box-shadow: 2px 3px 6px #00000066;
  font-weight: 500;
  transition: 0.5s;
  border: unset;
}

button.e-87:hover {
  background: hsl(var(--secondary));
  box-shadow: none;
  border: unset;
  color: unset;
}

.slots-table thead tr th:first-child {
  width: 180px;
}

.slots-table tbody tr td:first-child {
  width: 180px;
}

.slots-table thead tr th:nth-child(2) {
  width: 180px;
}

.slots-table tbody tr td:nth-child(2) {
  width: 180px;
}
.port-name-select .mantine-Select-root {
  width: 207.5px;
}
.e87-wrapper .tablescroll {
  overflow: auto;
  height: 581px;
}

.e-87-wrapper .slot-table-body-wrapper {
  height: calc(100vh - 430px);
}

.e87-wrapper.port-management .tablescroll {
  height: auto;
}

.tree-butons-contols-holder button {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tree-view-holder > ul {
  max-height: 450px;
  overflow-y: auto;
}

.e-87-wrapper .e87_tabs {
  background-color: unset !important;
}

.e-87-wrapper .side-button-wrapper,
.e-87-wrapper .tab_content {
  background-color: unset !important;
}

.e-87-wrapper .side-button-wrapper button {
  margin: 10px 0;
}

.tab_button-radio {
  margin: 0 0 -2px 0;
  min-width: 150px;
  height: 40px;
  position: relative;
}

.tab_button-radio label,
.tab_button-radio input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tab_button-radio input[type='radio'] {
  opacity: 0.011;
  /* z-index: 100; */
}

.tab_button-radio input[type='radio']:checked + label {
  background: #ffffff;
  color: #000;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.left-skew:before,
.left-skew:after {
  transition: 0.12s;
}

.left-skew:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 3px solid #ccc;
  border-width: 3px 3px 3px 0;
  transform: skewX(-20deg);
}
.left-skew:before {
  content: '';
  position: absolute;
  top: 0;
  left: -9px;
  bottom: 0;
  width: 17px;
  border: 3px solid #ccc;
  border-width: 3px 0 3px 3px;
  background: #ccc;
  z-index: 8;
  transform: skewX(-20deg);
  z-index: 123;
}

.tab_button-radio label {
  cursor: pointer;
  /* z-index: 90; */
  line-height: 1.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: lightgray;
}
.e-87-wrapper .tab_button-radio input[type='radio']:checked + label {
  background-color: theme('colors.tabActive');
  color: #000;
  opacity: 1;
}

.e-87-wrapper .tab_button-radio label {
  background-color: theme('colors.tabDefault');
}

.e-87-wrapper .tab_button-radio,
.e-87-wrapper .tab_button-radio label {
  border: unset !important;
  margin: 0 0 -2px 0;
    min-width: 150px;
    height: 40px;
    position: relative;
}

.e-87-wrapper .e87_tabs {
  gap: 2px;
}

.e87-wrapper.command-details .side-button-wrapper button {
  margin: 0 !important;
}

.e87-wrapper .grid-btns button.e-87 {
  line-height: 1.6rem !important;
  font-size: inherit;
}
.e87-wrapper  input[type='text'],.e87-wrapper select{
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
}

.tab_button-radio input[type=radio]:checked+label {
  
}
.bordeer-table td {border :2px solid rgb(226, 226, 226)}
@media screen and (max-width: 1480px) {
  .e87-wrapper.port-management .tablescroll {
    height: auto !important;
  }
}

/* @media screen and (min-width: 1500px) {
  .e87-wrapper.port-management .tablescroll {
    flex-grow: unset;
  }
} */
