.arm-1-wafer {
    opacity: 0;
    /* animation: arm1Anim 2s linear;
    animation-iteration-count: 3; */
}

.arm-1-wafer-animation {
    opacity: 1;
}

.load-lock-1 {
    transform: rotate(0deg) translate(0, 0);
    animation: loadLock1Anim 2s linear;
    transform-origin: center;
    transform-box: fill-box;
    animation-iteration-count: 3;
}

.load-lock-2 {
    transform: rotate(0deg) translate(0, 0);
    animation: loadLock1Anim 2s linear;
    transform-origin: center;
    transform-box: fill-box;
    animation-iteration-count: 3;
}

/* .loadloack-1-animation {
    animation: loadLock1Anim 4s linear infinite;
} */

/* .loadloack-2-animation {
    animation: loadLock1Anim 4s linear infinite;
} */

@keyframes arm1Anim {
    100% {
        opacity: 0;
      }
}

@keyframes loadLock1Anim {
    100% {
        transform: rotate(360deg);
      }
}
