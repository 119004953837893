.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.whight {
  height: 85vh;
}

.pulse {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.events-header-display {
  margin-top: 2.5%;
}

.eventScrollBox:hover {
  height: 340px;
  top: 70px;
}

.blink-button {
  /* display: inline-block;
  padding: 0;
  margin: 0;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px; */
  cursor: pointer;
  animation: blink-animation 1s infinite;
  
}

.blink-button path:nth-child(2) {
  fill: red;
}


@keyframes blink-animation {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
    filter: drop-shadow(4px 6px 1px rgb(5, 5, 5)); 
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
    filter: drop-shadow(0px 0px 10px rgb(201, 1, 188)); 
  }
}

.pulse {
  --color: rgb(255, 0, 255);
  --hover: rgb(255, 0, 255);
}

.pulse {
  color: var(--color);
  transition: 0.25s;
}

.pulse {
  background: none;
  border: 0;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
  padding: 1em 2em;
  border-radius: 300px;
}
.aqua-200 {background: #20BFF1;box-shadow: inset 0 0 5px 5px #15E1FD;}
.text-10p {    font-size: 10px;}
.rotationset {
  animation: rotateAnimation 5s linear infinite;
  transform-origin: center;
}

.middle-screen .iec-iframe {
  height: calc(100vh - 231px);
}

.middle-screen .gb-iframe {
  height: calc(100vh - 231px);
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.skelton-animate{
  animation:skeltonPulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

@keyframes skeltonPulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.disabled-btn {
  opacity: 0.5;
}

.disabled-btn:hover {
  opacity: 0.5;
  box-shadow: 1px 1px 3px 0 rgb(138, 141, 146), -2px -2px 3px 0 rgb(255, 255, 255) !important;
}

button[disabled] {
  opacity: .6;
  cursor: default;
  pointer-events: none !important;
}

.custom-input-field {
  max-width: 100%;
  border: 1px solid #ced4da;
  background-color: #fff;
  -webkit-transition: border-color 100ms ease;
  transition: border-color 100ms ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  height: 36px;
  -webkit-tap-highlight-color: transparent;
  line-height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  resize: none;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #000;
  display: block;
  text-align: left;
  min-height: 36px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
}

.custom-input-field:focus, .custom-input-field:focus-within {
  outline: none;
  border-color: #94d82d;
}

.bg-flip-blue {
  background: #D4E7F2;
  border: 1px solid #C1C1CA;
}

.bg-flip-btn {
  background: #8bdcf5;
  border: 1px solid #9ABFD9;
  box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.26);
}

.bg-flip-label-active {
  background: #0f0;
  box-shadow: inset 4px 4px 4px #72727240;
}